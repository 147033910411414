<template>
  <b-row class="mt-2">
    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-general')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-gears"></i>
          <span>General</span>
        </div>
      </b-card>
    </b-col>

    <!-- <b-col cols="4">
      <b-card @click="handleButtonClick('settings-departments')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-building"></i>
          <span>Departments</span>
        </div>
      </b-card>
    </b-col> -->
    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-labs')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-flask"></i>
          <span>Labs</span>
        </div>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-materials')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-recycle"></i>
          <span>Materials</span>
        </div>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-objectcodes')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-object-group"></i>
          <span>Object Code</span>
        </div>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-survey')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-square-poll-vertical"></i>
          <span>Survey</span>
        </div>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-terms')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-align-center"></i>
          <span>Terms & Conditions</span>
        </div>
      </b-card>
    </b-col>

    <b-col cols="4">
      <b-card @click="handleButtonClick('settings-note')" class="clickable-card">
        <div class="d-flex justify-content-between align-items-center">
          <i class="fa-solid fa-align-center"></i>
          <span>Quote Note</span>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  created() {},

  data() {
    return {};
  },

  methods: {
    handleButtonClick(buttonName) {
      this.$router.push({ name: buttonName });
    },
  },
};
</script>

<style scoped>
.clickable-card {
  cursor: pointer;
}

.clickable-card:hover {
  background-color: #ffffff20;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s ease;
}

i {
  color: #a51c30;
}
.clickable-card i {
  font-size: 3rem;
}

span {
  font-size: 1.5rem;
}
</style>
